const { default: Swal } = require("sweetalert2");

$(window).on('load', function () {
  if (document.getElementById("fad-iframe-acuant")) {
    const buttonSubmit = document.getElementById("submitButton");
    let iframeResponse = {};

    // Declare the side in front 
    let side = 0;

    const EVENT_MODULE = {
      INIT_MODULE: "INIT_MODULE",
      PROCESS_INIT: "PROCESS_INIT",
      PROCESS_ERROR: "PROCESS_ERROR",
      PROCESS_COMPLETED: "PROCESS_COMPLETED",
      MODULE_READY: "MODULE_READY",
    }

    // acuant credentials (Production Environment)
    const CREDENTIALS = {
      passiveUsername: "Acuant_Admin_PROD_US_F1@na-at.com.mx",
      passivePassword: "sp^^D5GqgGW5A1m",
      passiveSubscriptionId: "3b842956-c6f2-476a-8853-8cefa951f56f",
      acasEndpoint: "https://us.acas.acuant.net",
      livenessEndpoint: "https://us.passlive.acuant.net",
      assureidEndpoint: 'https://us.assureid.acuant.net'
    }

    const CONFIGURATION_FRONT = {
      views: {
        instructions: true,
        preview: true
      },
      customization: {
        fadCustomization: {
          colors: {
            primary: "#A70635",
            secondary: "#A70635",
            tertiary: "#363636",
          },
          buttons: {
            primary: {
              backgroundColor: "#A70635",
              backgroundColorDisabled: "#dcdcdc",
              labelColor: "#ffffff",
              labelColorDisabled: "#8e8e8e",
              border: "1px solid #A70635",
            },
          },
          fonts: {
            title: {
              fontSize: '25px',
              fontFamily: 'system-ui'
            },
            subtitle: {
              fontSize: '17px',
              fontFamily: 'system-ui'
            },
            content: {
              fontSize: '15px',
              fontFamily: 'system-ui'
            },
            informative: {
              fontSize: '12px',
              fontFamily: 'system-ui'
            },
            button: {
              fontSize: '17px',
              fontFamily: 'system-ui'
            }
          }
        },

        moduleCustomization: {
          legends: {
            initializing: "iniciando",
            processing: "procesando",
            scan: {
              none: "Escanea el frente de tu ID",
              smallDocument: "ACERCATE MÁS",
              goodDocument: "",
              capturing: "CAPTURANDO ",
              tapToCapture: "TOCA LA PANTALLA PARA CAPTURAR",
            },
            manualCapture: {
              mobile: {
                instruction: "Captura el frente de tu identificación",
                buttonNext: "Continuar",

              },
              desktop: {
                instruction: "Sube el frente de tu identificación",
                buttonNext: "Continuar",
                title: "Frente"

              },
            },

          },
          legendsInstructions: {
            title: 'Identificación',
            subtitle: 'Captura tu identifcación',
            buttonNext: 'Continuar'
          },
          legendsPreview: {
            title: 'Identificación',
            subtitle: 'Imagen frontal de tu identificación',
            confirmation: '¿Los datos de tu identificación son legibles?',
            buttonNext: 'Sí, continuar',
            buttonRetry: 'Volver a capturar'
          }
        }
      },
      pathDependencies: {
        // imageDirectory: 'ASSETS_URL'
      }
    };

    const CONFIGURATION_BACK = {
      views: {
        instructions: false,
        preview: true
      },
      customization: {
        fadCustomization: {
          colors: {
            primary: "#A70635",
            secondary: "#A70635",
            tertiary: "#363636",
          },
          buttons: {
            primary: {
              backgroundColor: "#A70635",
              backgroundColorDisabled: "#dcdcdc",
              labelColor: "#ffffff",
              labelColorDisabled: "#8e8e8e",
              border: "1px solid #A70635",
            },
          },
          fonts: {
            title: {
              fontSize: '25px',
              fontFamily: 'system-ui'
            },
            subtitle: {
              fontSize: '17px',
              fontFamily: 'system-ui'
            },
            content: {
              fontSize: '15px',
              fontFamily: 'system-ui'
            },
            informative: {
              fontSize: '12px',
              fontFamily: 'system-ui'
            },
            button: {
              fontSize: '17px',
              fontFamily: 'system-ui'
            }
          }
        },

        moduleCustomization: {
          legends: {
            initializing: "iniciando",
            processing: "procesando",
            scan: {
              none: "Ahora la parte posterior",
              smallDocument: "ACERCATE MÁS",
              goodDocument: "",
              capturing: "CAPTURANDO ",
              tapToCapture: "TOCA LA PANTALLA PARA CAPTURAR",
            },
            manualCapture: {
              mobile: {
                instruction: "Captura el reverso de tu identificación",
                buttonNext: "Continuar",

              },
              desktop: {
                instruction: "Sube el reverso de tu identificación",
                title: "Reverso",

              },
            },


          },
          legendsInstructions: {
            title: 'Identificación',
            subtitle: 'Captura tu identifcación',
            buttonNext: 'Continuar'
          },
          legendsPreview: {
            title: 'Identificación',
            subtitle: 'Imagen trasera de tu identificación',
            confirmation: '¿Los datos de tu identificación son legibles?',
            buttonNext: 'Sí, continuar',
            buttonRetry: 'Volver a capturar'
          }
        }
      },
      pathDependencies: {
        // imageDirectory: 'ASSETS_URL'
      }
    };

    const ERROR_CODE = {
      REQUIRED_CREDENTIAL: -1,
      FAIL_INITIALIZATION: -2,
      UNSUPPORTED_CAMERA: -3,
      FAIL_INITIALIZATION_CAMERA_U: -4,
      FAIL_CREATION_INSTANCE_DOCUMEN: -5,
      FAIL_UPLOAD_IMAG: -6,
      FAIL_GET_OC: -7,
      FAIL_GET_FACE_IMAG: -8,
      FACE_IMAGE_URL_NOT_FOUND: -9,
      FACE_IMAGE_NOT_FOUN: -10,
      RESOURCES_COULD_NOT_BE_LOADED: -11,
      // DOMAIN_NOT_ALLOWED: -12,
      UNEXPECTED_ACUANT_ERROR: -12,
      UNSUPPORTED_IMAGE: -13,
      IMAGE_BLURRY: -14
    };

    /**
     * Module parameters for set the configuration, credentias and
     * id properties.
     * The initial configuration is for the id-front process; is
     * important to do not request idData if the documentInstance id
     * will be used in id-back process; otherwise an error will be thrown.
     */



    let moduleParams = {
      credentials: CREDENTIALS,
      configuration: side ? CONFIGURATION_BACK : CONFIGURATION_FRONT,
      side, // 0 - front id, 1 - back id
      idData: false, // true - ocr, false - without this data
      idPhoto: false, // true - get imaghen face of id, false - without this data
      imageQuality: 1, // quality of image id, range 0 - 1
    }

    class ResponseEvent {
      event;
      data;
      constructor(event, data) {
        this.event = event;
        this.data = data;
      }
    }

    class Result {
      id; // image of identification (image.data) and relevant information (sharpness, glare)
      idData; // ocr idData.ocr;
      idPhoto; // image of the face cutout
      constructor(data) {
        this.id = data.id;
        this.idData = data.idData;
        this.idPhoto = data.idPhoto;
      }
    }

    function initIframe() {
      const iframe = document.getElementById("fad-iframe-acuant");
      const tkn = "dUU1V3pvTFVCVXRoM1JqMkVmcDVISnQxRWd3Y1N4b3o3RFVURk1LQWR5R0ZDUkVBa0QrTFM5bW1WL09TZzRvNnhVRFNKamdNWlR3aUlTYTZuUTNPMHdoZ3B4S3hvWjgxVGdKbmpTSXJBMDJud3FVaHFaNmVJUDNGUUZsMEEvSFBBWDZyVjBwaDRlcm95RFJGL1dmZFdRPT0=";
      const url = `https://apiframe.firmaautografa.com/fad-iframe-acuant?tkn=${tkn}`;
      iframe.src = url;
    }

    function initModule() {
      const iframe = document.getElementById("fad-iframe-acuant");
      console.log(moduleParams)

      iframe.contentWindow.postMessage(new ResponseEvent(EVENT_MODULE.INIT_MODULE, moduleParams), iframe.src);
    }

    function parseDate(string_date) {
      const date_obj = new Date(parseInt(string_date.match(/\d{12}/)[0]));
      return `${date_obj.getFullYear()}-${date_obj.getMonth() + 1}-${date_obj.getDate()}`;
    }

    async function getImageFile(base64String, filename) {
      const response = await fetch(base64String);
      const blob = await response.blob();
      return new File([blob], filename, { type: "image/jpg" });
    }

    async function saveData() {
      const url = `${window.location.origin}/identities`;
      const formData = new FormData();

      formData.append('authenticity_token', $('meta[name="csrf-token"]').attr('content'));
      formData.append('identity[fullname]', iframeResponse.idData.ocr.fullName);
      formData.append('identity[first_name]', iframeResponse.idData.ocr.firstName);
      formData.append('identity[middle_name]', iframeResponse.idData.ocr.middleName);
      formData.append('identity[last_name]', iframeResponse.idData.ocr.surname);
      formData.append('identity[given_name]', iframeResponse.idData.ocr.givenName);
      formData.append('identity[father_surname]', iframeResponse.idData.ocr.fathersSurname);
      formData.append('identity[mother_surname]', iframeResponse.idData.ocr.mothersSurname);
      formData.append('identity[date_of_birth]', iframeResponse.idData.ocr.birthDate);
      formData.append('identity[curp]', iframeResponse.idData.ocr.curp);
      formData.append('identity[elector_key]', iframeResponse.idData.dataFields.find(item => item.Name === "VIZ Registration Number")?.Value);
      formData.append('identity[customer_id]', $("#customer_id").val());
      formData.append('identity[front_image_data]', await getImageFile(sessionStorage.getItem("idFront"), "front_image"));
      formData.append('identity[back_image_data]', await getImageFile(iframeResponse.id.image.data, "back_image"));
      formData.append('identity[document_photo_data]', await getImageFile(iframeResponse.idPhoto, "document_photo"));
      formData.append('identity[gender]', iframeResponse.idData.ocr.sex);
      formData.append('identity[address]', iframeResponse.idData.ocr.address);
      formData.append('identity[nationality]', iframeResponse.idData.ocr.issuingStateCode);
      formData.append('identity[date_of_expiry]', parseDate(iframeResponse.idData.ocr.expirationDate));
      formData.append('identity[emision_number]', iframeResponse.idData.ocr.registrationYearAndVerificationNumber);
      formData.append('identity[emision_date]', parseDate(iframeResponse.idData.ocr.issueDate));
      formData.append('identity[uuid]', sessionStorage.getItem('documentInstance'));
      formData.append('identity[document_type]', iframeResponse.idData.ocr.documentClassName);
      formData.append('identity[line1]', iframeResponse.idData.dataFields.find(item => item.Name === "MRZ1")?.Value);
      formData.append('identity[line2]', iframeResponse.idData.dataFields.find(item => item.Name === "MRZ2")?.Value);
      formData.append('identity[line3]', iframeResponse.idData.dataFields.find(item => item.Name === "MRZ3")?.Value);

      return await fetch(url, {
        method: 'POST',
        body: formData
      });
    }

    function showResult(response) {
      const containerResult = document.getElementById("container-result");
      const containerIframe = document.getElementById("container-iframe-acuant");
      const imageIdFront = document.getElementById("image-id-front");
      const imageIdBack = document.getElementById("image-id-back");

      containerIframe.style.display = "none";
      containerResult.style.display = "flex";
      imageIdFront.src = sessionStorage.getItem("idFront");
      imageIdBack.src = response.id.image.data;

      iframeResponse = response;
    }

    /**
     * Change the moduleParams configuration for
     * the id-back process using the documentInstance id.
     * IMPORTANT CHANGES FOR id-back PROCESS:
     * - side = 1
     * - idData = true
     * - documentInstance = The documentInstance returned in the id-front process.
     */
    function initBackIdProcess() {
      const iframe = document.getElementById("fad-iframe-acuant");
      side = 1

      moduleParams.documentInstance = sessionStorage.getItem('documentInstance');
      moduleParams.configuration = CONFIGURATION_BACK;
      moduleParams.idData = true;
      moduleParams.idPhoto = true;
      moduleParams.side = 1;
      iframe.src = '';
      setTimeout(() => {
        initIframe();
      }, 100);
    }
    // subscribe to message event to receive the events from the iframe
    window.addEventListener("message", (message) => {
      // IMPORTANT: check the origin of the data
      if (message.origin.includes("firmaautografa.com")) {
        if (message.data.event === EVENT_MODULE.MODULE_READY) {
          // MODULE_READY
          initModule();
        }
        if (message.data.event === EVENT_MODULE.PROCESS_INIT) {
          // PROCESS_INIT
        } else if (message.data.event === EVENT_MODULE.PROCESS_ERROR) {
          // PRROCESS_ERROR
          if (message.data.data.code === ERROR_CODE.UNSUPPORTED_CAMERA) {
            Swal.fire({
              title: '<span class="text-danger">Cámara no soportada, intenta en otro dispositivo</span>',
              text: message.data.data.error,
              icon: 'error',
              showConfirmButton: false
            });
          } else {
            Swal.fire({
              title: `<span class="text-danger">${message.data.data.error}</span>`,
              text: 'Formato de imagen no soportado',
              icon: 'error',
              showConfirmButton: false
            });
          }
        } else if (message.data.event === EVENT_MODULE.PROCESS_COMPLETED) {
          // PROCESS_COMPLETED
          const result = new Result(message.data.data);

          // If side is 0, the next step is to process the back side of the id (1)
          if (moduleParams.side === 0) {
            // documentInstance will be use inside the back-id capture process.
            sessionStorage.setItem('documentInstance', message.data.data.documentInstance);
            // Id front image in base64
            // IMPORTANT: If you need the id-front image for a further process; storage
            // the image in this step
            sessionStorage.setItem("idFront", message.data.data.id.image.data);
            initBackIdProcess();
          } else {
            // If side is 1, the front id and back id where processed
            showResult(result);
          }
        }
      } else return;
    });

    buttonSubmit.addEventListener("click", function () {
      $("#loaderModal").modal('toggle');
      const response = saveData();
      response.then(res => res.json())
        .then(data => {
          $("#loaderModal").modal('toggle');
          if (data.status == "create") {
            Swal.fire({
              text: data.message,
              icon: 'success',
              showConfirmButton: false,
              footer: `<button onclick="sessionStorage.setItem('shouldRefresh', 'true'); window.location.href='${window.location.origin}/identities/${data.identity_id}/facetec';" class="btn btn-success">Continuar</button>`
            });
          } else {
            Swal.fire({
              title: '<span class="text-danger">Error</span>',
              text: data.message,
              icon: 'error',
              showConfirmButton: true,
            });
          }
        }).catch(err => {
          Swal.fire({
            title: '<span class="text-danger">Ha ocurrido un error inesperado</span>',
            text: err.message,
            icon: 'error',
            showConfirmButton: true
          });
        });
    });

    initIframe();
  }
});
