import Swal from 'sweetalert2';

/**
 * It validates that the given email address is on a valid format.
 *
 * @param {string} emailAddress - The email of the customer
 * @returns {boolean}
 */
const isValidEmailAddress = emailAddress => {
  const pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  return pattern.test(emailAddress);
}

const clearForm = () => {
  $('#frmF1').find('.form-control').val('');
  $('#frmF1').find('.form-check-input').prop( 'checked', false );
}

$(window).on('load', function () {
  $("#btnRedirect").on('click',function (e) {
    e.preventDefault();
    window.location.reload(true);
  });

  $("#modal-signup").submit('#signUp', function (event) {
    event.preventDefault();
    const email = $("#signup_customer_email").val();
    const password = $("#signup_customer_password").val();
    const password_confirmation = $("#signup_customer_password_confirmation").val();
    const terms = $("#accept_terms").prop('checked');
    const recaptcha = $("#g-recaptcha-response-2").val();
    if (email != '' && password != '' && password_confirmation != '' && recaptcha != '' && terms) {
      if (isValidEmailAddress(email)) {
        if (password == password_confirmation) {
          if (password.length >= 8) {
            event.target.submit();
          } else {
            Swal.fire({
              icon: 'error',
              text: 'La contraseña debe tener minimo 8 carácteres.',
            });
          }
        } else {
          Swal.fire({
            icon: 'error',
            text: 'Las contraseñas no coinciden.',
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          text: 'El email no es valido.',
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        text: 'Todos los campos son requeridos requeridos.',
      });
    }
  });
});
