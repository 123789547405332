const { default: Swal } = require("sweetalert2");

$(window).on('load', function () {
  if (document.getElementById("fad-iframe-firma")) {
    let iframeResponse = {};

    // events available
    const EVENT_MODULE = {
      PROCESS_INIT: "PROCESS_INIT",
      PROCESS_ERROR: "PROCESS_ERROR",
      PROCESS_COMPLETED: "PROCESS_COMPLETED",
      ENTRY_PATH: "ENTRY_PATH"
    }

    function showButton(response) {
      const containerResult = document.getElementById("container-result");
      const containerIframe = document.getElementById("container-iframe-firma");

      containerIframe.style.display = "none";
      containerResult.style.display = "flex";

      iframeResponse = response;
    }

    // subscribe to message event to recive the events from the iframe
    window.addEventListener("message", (message) => {
      // IMPORTANT: check the origin of the data!
      if (message.origin.includes("firmaautografa.com")) {
        if (message.data.event === EVENT_MODULE.PROCESS_INIT) {
          // only informative
          console.log("Process init");
        } else if (message.data.event === EVENT_MODULE.PROCESS_ERROR) {
          // restart component and send error
          console.log("Unespected error:" + JSON.stringify(message.data.data));
        } else if (message.data.event === EVENT_MODULE.PROCESS_COMPLETED) {
          // end of the process
          // console.log("Process completed");
          showButton(message.data.data);
        } else if (message.data.event === EVENT_MODULE.ENTRY_PATH) {
          // show current path
          console.log("Current path: " + message.data.data);
        }
      } else return;
    });

    function initIframe() {
      const iframe = document.getElementById("fad-iframe-firma");
      const URL = `https://mobile.firmaautografa.com/main${window.location.search}`

      // set src to iframe
      iframe.src = URL;

      // show loader - optional
      iframe.onload = () => {
        // hide loader - optional
      };
    }

    initIframe();
  }
});
