const { default: Swal } = require("sweetalert2");

$(window).on('load', function () {
  if (sessionStorage.getItem('shouldRefresh') === 'true') {
    sessionStorage.removeItem('shouldRefresh');
    location.reload();
  }

  if (document.getElementById("fad-iframe-facetec")) {
    initIframe();
    const buttonFacetec = document.getElementById("submitFacetec");
    let iframeResponse = {};

    // events available
    const EVENT_MODULE = {
      INIT_MODULE: 'INIT_MODULE',
      PROCESS_INIT: 'PROCESS_INIT',
      PROCESS_ERROR: 'PROCESS_ERROR',
      PROCESS_COMPLETED: 'PROCESS_COMPLETED',
      MODULE_READY: 'MODULE_READY',
    };


    const MW_CONFIGURATION = {
      useMiddleware: true,
      app: "f1npulsa",
      additionalInfo: {
        /**
         * For validation process VALIDATION = 1,
         * For enrollments process ENROLLMENT = 2,
         * For signatures process SIGNATURE = 3,
         * For videoconferences process VIDEOCONFERENCE = 4,
         * For other kind of process OTHER = 5
         */
        processTypeId: 5,
      },
    };


    // facetec credentials, provided by Na-at Technologies
    const CREDENTIALS = {
      deviceKeyIdentifier: "dC1PnDvQFFAkQayLllJ2CACxis0AO548",
      baseURL: "https://facetec.firmaautografa.com",
      publicFaceScanEncryptionKey:
        "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5PxZ3DLj+zP6T6HFgzzk\nM77LdzP3fojBoLasw7EfzvLMnJNUlyRb5m8e5QyyJxI+wRjsALHvFgLzGwxM8ehz\nDqqBZed+f4w33GgQXFZOS4AOvyPbALgCYoLehigLAbbCNTkeY5RDcmmSI/sbp+s6\nmAiAKKvCdIqe17bltZ/rfEoL3gPKEfLXeN549LTj3XBp0hvG4loQ6eC1E1tRzSkf\nGJD4GIVvR+j12gXAaftj3ahfYxioBH7F7HQxzmWkwDyn3bqU54eaiB7f0ftsPpWM\nceUaqkL2DZUvgN0efEJjnWy5y1/Gkq5GGWCROI9XG/SwXJ30BbVUehTbVcD70+ZF\n8QIDAQAB\n-----END PUBLIC KEY-----",
      productionKeyText: {
        domains: "firmaautografa.com,afiliacionesc.fna.gov.co,creditoenlinea.csn.coop,afiliaciones.fna.gov.co,apoio.assinaturaautografadigital.com.br,biometria.proyectopcd.com,api.fad.santander.com.mx,fad.services.mx.fad.mx.corp,fad-gateway-mx-api-gateway-dev.apps.str01.mex.dev.mx1.paas.cloudcenter.corp,fad.gateway.mx.api.gateway.pre.mx.corp,f1npulsa.com.mx",
        expiryDate: "2024-01-10",
        key: "0030450220071ff9efc61c21590ae1427a80c0c720bdde5f0251da656fb0f20741c91b6c66022100e49c5ea42668b44763e9765360a10e6813dafcf1a2a09de7553fe7e058b8352c"
      }
    };

    const CONFIGURATION = {
      orientationAllowed: {
        landscape: true,
      },

      views: {
        instructions: true,
      },


    };



    // errors
    const ERROR_CODE = {
      NEVER_INITIALIZED: 0,
      MISSING_GUIDANCE_IMAGES: 1,
      TIMEOUT: 2,
      CONTEXT_SWITCH: 3,
      PROGRAMMATICALLY_CANCELLED: 4,
      ORIENTATION_CHANGE_DURING_SESSION: 5,
      LANDSCAPE_MODE_NOT_ALLOWED: 6,
      USER_CANCELLED: 7,
      USER_CANCELLED_FROM_NEW_USER_GUIDANCE: 8,
      USER_CANCELLED_FROM_RETRY_GUIDANCE: 9,
      USER_CANCELLED_WHEN_ATTEMPTING_TO_GET_CAMERA_PERMISSIONS: 10,
      LOCKED_OUT: 11,
      CAMERA_NOT_ENABLED: 12,
      NON_PRODUCTION_MODE_DEVICE_KEY_IDENTIFIER_INVALID: 13,
      DOCUMENT_NOT_READY: 14,
      SESSION_IN_PROGRESS: 15,
      CAMERA_NOT_RUNNING: 16,
      INITIALIZATION_NOT_COMPLETED: 17,
      UNKNOWN_INTERNAL_ERROR: 18,
      USER_CANCELLED_VIA_CLICKABLE_READY_SCREEN_SUBTEXT: 19,
      NOT_ALLOWED_USE_IFRAME_CONSTRUCTOR: 20,
      NOT_ALLOWED_USE_NON_IFRAME_CONSTRUCTOR: 21,
      I_FRAME_NOT_ALLOWED_WITHOUT_PERMISSION: 22,
      STILL_LOADING_RESOURCES: 23,
      RESOURCES_COULD_NOT_BE_LOADED_ON_LAST_INIT: 24,
      RESOURCES_COULD_NOT_BE_LOADED: 25,
      REQUIRED_CREDENTIALS: 26,
    };

    class ResponseEvent {
      event;
      data;
      constructor(event, data) {
        this.event = event;
        this.data = data;
      }
    }

    class Result {
      faceScan; // face scan data
      img; // face image ( base64 )
      imgLowQuality; // low quality face image ( base64 )
      sessionId;
      status;
      isCompletelyDone;
      constructor(data) {
        this.faceScan = data.faceScan;
        this.img = data.auditTrail[0];
        this.imgLowQuality = data.lowQualityAuditTrail[0];
        this.sessionId = data.sessionId;
        this.status = data.status;
        this.isCompletelyDone = data.isCompletelyDone;
      }
    }

    function initIframe() {
      // get iframe
      const iframe = document.getElementById('fad-iframe-facetec');
      // url - https://apiframe.firmaautografa.com/fad-iframe-facetec

      const urlProd = `https://apiframe.firmaautografa.com/fad-iframe-facetec?tkn=dUU1V3pvTFVCVXRoM1JqMkVmcDVISnQxRWd3Y1N4b3o3RFVURk1LQWR5R0ZDUkVBa0QrTFM5bW1WL09TZzRvNnhVRFNKamdNWlR3aUlTYTZuUTNPMHdoZ3B4S3hvWjgxVGdKbmpTSXJBMDJud3FVaHFaNmVJUDNGUUZsMEEvSFBBWDZyVjBwaDRlcm95RFJGL1dmZFdRPT0`;


      const urlTest = `https://devapiframe.firmaautografa.com/fad-iframe-facetec?tkn=dUU1V3pvTFVCVXRoM1JqMkVmcDVISnQxRWd3Y1N4b3o3RFVURk1LQWR5SEFwb1NMZ0QzOWc2cWVHZ0hIbVN2eA==`;
      // set src to iframe
      iframe.src = urlProd;
    }

    function initModule() {
      const iframe = document.getElementById("fad-iframe-facetec");
      iframe.contentWindow.postMessage(
        new ResponseEvent(EVENT_MODULE.INIT_MODULE, {
          configuration: CONFIGURATION,
          middleware: MW_CONFIGURATION,
        }),
        iframe.src
      );
    }

    async function getImageFile(base64String, filename) {
      const response = await fetch(base64String);
      const blob = await response.blob();
      return new File([blob], filename, { type: "image/jpg" });
    }

    async function saveData() {
      const url = `${window.location.origin}/identities/${$("#identity_id").val()}`;
      const formData = new FormData();

      formData.append('authenticity_token', $('meta[name="csrf-token"]').attr('content'));
      formData.append('identity[face_image_data]', await getImageFile(`data:image/jpeg;base64, ${iframeResponse.img}`, "face_image"));
      // Audit Trail Image is the same as Face Image
      formData.append('identity[low_quality_audit_trail_image_data]', await getImageFile(`data:image/jpeg;base64, ${iframeResponse.imgLowQuality}`, "low_quality_audit_trail_image"));
      formData.append('identity[face_scan]', `${iframeResponse.faceScan}`);
      formData.append('identity[status]', 'Aprobada');
      return await fetch(url, {
        method: 'PATCH',
        body: formData
      });
    }

    function showResults(response) {
      debugger
      console.log('Debugging face image')
      const result = new Result(response);
      const img = `data:image/jpeg;base64, ${result.img}`;

      document.getElementById("container-iframe-facetec").style.display = "none";
      document.getElementById("container-iframe-facetec").style.height = "100%";
      document.getElementById("container-result").style.display = "flex";
      document.getElementById("image-id").src = img;


      iframeResponse = result;
    }

    // subscribe to message event to receive the events from the iframe
    window.addEventListener("message", (message) => {
      console.log("message", message);
      // IMPORTANT: check the origin of the data
      if (message.origin.includes("firmaautografa.com")) {
        if (message.data.event === EVENT_MODULE.MODULE_READY) { // MODULE_READY
          // call initModule with the specific config
          initModule();
        }
        if (message.data.event === EVENT_MODULE.PROCESS_INIT) { // PROCESS_INIT
          // only informative
          console.log("Process init");
        } else if (message.data.event === EVENT_MODULE.PROCESS_ERROR) { // PRROCESS_ERROR
          // console.log(message.data.data);
          if (message.data.data.code === ERROR_CODE.CAMERA_NOT_RUNNING) {
            Swal.fire({
              title: '<span class="text-danger">Error</span>',
              text: "Cámara no soportada, intenta en otro dispositivo",
              icon: 'error',
              showConfirmButton: true,
            });
          } else if (message.data.data.code === ERROR_CODE.INITIALIZATION_NOT_COMPLETED) {
            // restart component
          } else {
            Swal.fire({
              title: '<span class="text-danger">Error</span>',
              text: "Ha ocurrido un error inesperado, intentelo de nuevo",
              icon: 'error',
              showConfirmButton: true,
            });
            // restart component
            console.log(JSON.stringify(message.data.data));
          }
        } else if (message.data.event === EVENT_MODULE.PROCESS_COMPLETED) { // PROCESS_COMPLETED
          // console.log("Process completed");
          showResults(message.data.data);
        }
      } else return;
    });

    buttonFacetec.addEventListener("click", function () {
      $("#loaderModal").modal('toggle');
      const response = saveData();
      response.then(res => res.json())
        .then(data => {
          $("#loaderModal").modal('toggle');
          if (data.status == "ok") {
            let id = localStorage.getItem('solicitudID'); // Retrieve the id from local storage


            Swal.fire({
              text: data.message,
              icon: 'success',
              showConfirmButton: false,
              // Obtener el id de local storage y navegar a la solicitud en la pagina correspondiente

              footer: `<a href="${window.location.origin}/loanRequests/${id}/edit/datos_vivienda" class="btn btn-success">Continuar</a>`
            });
          } else {
            Swal.fire({
              title: '<span class="text-danger">Error</span>',
              text: data.message,
              icon: 'error',
              showConfirmButton: true,
            });
          }
        }).catch(err => {
          Swal.fire({
            title: '<span class="text-danger">Ha ocurrido un error inesperado</span>',
            text: err.message,
            icon: 'error',
            showConfirmButton: true
          });
        });
    });
  }
});
