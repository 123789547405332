import Swal from 'sweetalert2';

$(window).on('load', function () {
  if (document.getElementById('test-of-life')) {
    Swal.fire({
      icon: 'info',
      title: '<strong class="text-info">!AVISO!</strong>',
      text:
        'Para las fotos es requerido que el dispositivo cuente con una cámara de resolución de al menos ' +
        '4 megapixeles, a color y estar en un lugar con buena iluminación.',
        confirmButtonColor: '#2a1d56'
    })
  }

  const check_verification = async (identity_id) => {
    const url = `${window.location.origin}/identities/${identity_id}/results.json`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  }

  // Retrive identity information from Vdid
  $("#identities_data").on('click', '.retrieve-data', function () {
    this.firstElementChild.classList.add('fa-spin');
    check_verification(this.id)
    .then((res) => {
      this.firstElementChild.classList.remove('fa-spin');
      Swal.fire({
        icon: 'success',
        text: res.identity.message
      });
      $(`td[data-tstatus='${res.identity.id}']`).html(res.identity.transaction_status);
      $(`span[data-badge='${res.identity.id}']`).html(res.identity.status);
      if (res.identity.status === 'Aprobada') {
        $(`td[data-refresh='${res.identity.id}']`).addClass('d-none');
        $(`td[data-badge='${res.identity.id}']`).removeClass();
        $(`span[data-badge='${res.identity.id}']`).addClass("badge badge-success");
      } else if (res.identity.status === 'No aprobada') {
        $(`td[data-refresh='${res.identity.id}']`).addClass('d-none');
        $(`td[data-badge='${res.identity.id}']`).removeClass();
        $(`span[data-badge='${res.identity.id}']`).addClass("badge badge-danger");
      }
      $(`td[data-gresult='${res.identity.id}']`).html(res.identity.global_result);
      $(`td[data-gresultd='${res.identity.id}']`).html(res.identity.global_result_description);
    })
    .catch(err => {
      console.log('An error ocurred:', err);
    })
  });
});
